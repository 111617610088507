<template>
    <v-dialog v-model="showLoginDialog" max-width="600">
      <v-card>
        <v-card-title>Login or Register</v-card-title>
        <v-card-text>
            <p>{{ message }}</p>
            <p v-if="mode === 'LOGIN'">
              No account yet? 
              <v-btn outlined color="primary" class="ms-2" @click="mode='REGISTER'">Create a New Account</v-btn>
            </p>
            <p v-else>
              Already have an account? 
              <v-btn outlined color="primary" class="ms-2" @click="mode='LOGIN'">Login Instead</v-btn>
              <v-btn text small class="ms-2" color="primary" to="/account/passwordrecovery">{{$t('account.login.forgot-your-password')}}</v-btn>
            </p>
            <v-form
              ref="loginForm"
              v-model="valid"
              lazy-validation
              >
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="$t('account.register.email')"
                  prepend-icon="fa-envelope"
                  type="email"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('account.register.password')"
                  required
                  prepend-icon="fa-lock"
                  :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  @click:append="passwordShow = !passwordShow"
              ></v-text-field>

              <v-checkbox v-if="mode === 'REGISTER'" v-model="termsAccepted" hide-details ref="termsCheckbox">
                <template v-slot:label>
                  <div class="d-flex" style="flex-direction:column;">
                    <i18n path="account.register.terms_privacy_v2" tag="div" class="">
                      <router-link to='privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</router-link>
                      <router-link to='termsofservice'>{{$t('account.register.terms_privacy_terms')}}</router-link>
                    </i18n>
                    <div class="text-muted">{{$t('account.register.terms_privacy_subtext')}}</div>
                  </div>
                </template>
              </v-checkbox>
              </v-form>

              <v-alert v-if="error" type="error">{{error}}</v-alert>

              <v-btn v-if="mode === 'LOGIN'" :disabled="!email || !password" block color="primary" @click="login()" :loading="$store.getters.isLoading">
                {{ $t('account.login.submit') }}
              </v-btn>
              <v-btn v-else :disabled="!termsAccepted || !email || !password" block color="primary" @click="registerOrLogin()" :loading="$store.getters.isLoading">
                {{ $t('account.register.submit') }}
              </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword  } from "firebase/auth";

export default {
  name: "LoginOrRegisterDialog",
  components: {},
  props: {
    mode: String,
    message: String,
  },
  data: function () {
    return {
      showLoginDialog: false,
      error: null,
      valid: true,
      email: null,
      password: null,
      isLoggingInOrRegistering: false,
      termsAccepted: false,
      passwordShow: false,
        emailRules: [
        v => !!v || i18n.t('account.register.email-is-required'),
        v => /.+@.+/.test(v) || i18n.t('account.register.email-must-be-valid')
      ],
      passwordRules: [
        v => !!v || 'Password is Required',
        v => !!v && v.length >= 6 || 'Password must be at least 6 characters or more'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  async mounted() {
  },
  methods: {

    open(email) {
      this.email = email;
        this.showLoginDialog = true;
    },

    async registerOrLogin(emailOverride, passwordOverride) {
      const auth = getAuth();
      const request = {
        email: emailOverride || this.email,
        password: passwordOverride || this.password,
        name: null,
      }
      try {
        this.isLoggingInOrRegistering = true;
        var response = await createUserWithEmailAndPassword(auth, request.email, request.password);
        var ip = null;
        try {
          ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;
        }
        catch {  }
        await this.$store.dispatch('setUser', response.user);
        await profileService.post({ ip: ip }); 
        this.$analytics.event('login', { method : 'Email' });

        this.showLoginDialog = false;
        this.$emit('submit', loginResponse.user);
        return true;
      }
      catch (ex) {
        console.log('register ' , ex.code);
        await new Promise(r => setTimeout(r, 500));

        if (ex.code == 'auth/email-already-in-use') {
          this.error = 'An account with the same email already exists. Please login or request a new password.';
          this.mode = "LOGIN";
        }

        //this.error = ex.message;
        try{
          // try to login with these credentials, might be someone trying to login
          this.isLoggingInOrRegistering = true;
          var loginResponse = await signInWithEmailAndPassword(auth, request.email, request.password);
          if (loginResponse && loginResponse.user) {
            await this.$store.dispatch("setUser", loginResponse.user);
            this.showLoginDialog = false;
            this.$analytics.event('login', { method : 'Email' });
            this.$emit('submit', loginResponse.user);
            return true;
          }
        }
        catch (loginError){
        console.log('login ' , loginError.code)
        }

      }
      return false;
    },    

    async login() {
      const auth = getAuth();
      const request = {
        email: this.email,
        password: this.password,
        name: null,
      }
      try {

          // try to login with these credentials, might be someone trying to login
          this.isLoggingInOrRegistering = true;
          var loginResponse = await signInWithEmailAndPassword(auth, request.email, request.password);
          if (loginResponse && loginResponse.user) {
            await this.$store.dispatch("setUser", loginResponse.user);
            this.showLoginDialog = false;
            this.$analytics.event('login', { method : 'Email' });
            this.$emit('submit', loginResponse.user);
            return true;
          }
        }
        catch (loginError){
          this.error = 'An error occurred during login. Please check email and password.'
        console.log('login ' , loginError.code)
        }

      return false;
    },    

  },
};
</script>

<style lang="scss" scoped>

</style>
